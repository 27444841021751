<template>
  <v-container class="">
    <h1 class="font-semibold text-xl">Leaderboard</h1>
    <p class="text-subtitle-1 font-weight-bold">
      Got questions? Call or Whatsapp {{ data.phone }}
    </p>
    <div>
      <v-tabs color="primary" center-active v-model="leaderboardTab">
        <v-tab>Giftcard</v-tab>
        <v-tab>Crypto Sales</v-tab>
        <v-tab>Crypto Purchase</v-tab>
      </v-tabs>

      <v-card :loading="loadingData" elevation="2" class="">
        <div class="mt-5">
          <v-tabs color="accent" center-active centered v-model="selection">
            <v-tab>Day</v-tab>
            <v-tab>Month</v-tab>
            <v-tab>Year</v-tab>
          </v-tabs>
        </div>

        <div class="mt-5 grey lighten-5">
          <v-row>
            <v-col cols="12" v-for="user in users" :key="user.user_id">
              <div class="d-flex w-100 justify-space-between align-center px-5">
                <div class="d-flex">
                  <div class="mr-5">
                    <v-avatar
                      :color="getColorBg()"
                      :size="$vuetify.breakpoint.smAndDown ? 35 : 65"
                      >{{ user.name | getInitials }}</v-avatar
                    >
                  </div>
                  <div>
                    <h4
                      class="text-capitalize font-weight-medium text-md-body-1 text-lg-h6"
                    >
                      {{ user.name | getInitials }}
                    </h4>
                    <p :title="user.username" class="subtitle-2 text-truncate">
                      {{
                        user.username
                          | trucateUsername($vuetify.breakpoint.mdAndUp)
                      }}
                    </p>
                  </div>
                </div>
                <div class="text-md-body-2 text-lg-h5">
                  {{ userCountry === 'Ghana' ? '¢' : '₦' }}
                  {{ user.amount | formatAmount }}
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import dataApi from '../../api/systemData'
import { numberWithCommas } from '../../utils/helpers'

export default {
  name: 'Leaderboard',
  data() {
    return {
      numberWithCommas,
      leaderboardTab: 0,
      data: {},
      users: [],
      selection: 0,
      loadingData: false
    }
  },
  computed: {
    ...mapState({
      user: ({ user }) => user.user
    })
  },
  watch: {
    leaderboardTab: function (newValue, oldValue) {
      // 0 - Giftcard
      // 1 - Coin Sale
      // 2 - Coin Purchase
      if (oldValue !== newValue)
        this.getLeaderboard({
          period: this.selection,
          category: this.leaderboardTab
        })
    },
    selection: function (newValue, oldValue) {
      // 0 = Day
      // 1 - Month
      // 2 -Year
      if (oldValue !== newValue)
        this.getLeaderboard({
          period: this.selection,
          category: this.leaderboardTab
        })
    }
  },
  filters: {
    trucateUsername(value) {
      if (!value) return ''
      return value.length > 10
        ? `${value.toString().substring(0, 14)}...`
        : value
    },
    getInitials(value) {
      if (!value) return 'AA'
      const s = value.split(' ')
      return `${s[0][0]}${s[1][0]}`
    },
    formatAmount(value) {
      if (!value) return 0
      return numberWithCommas(Number.parseFloat(value).toFixed(2))
    }
  },
  created() {
    this.getData()
    this.getLeaderboard({
      period: this.selection,
      category: this.leaderboardTab
    })
  },
  methods: {
    async getLeaderboard({ period, category }) {
      this.loadingData = true
      const periodText =
        period === 0
          ? 'day'
          : period === 1
          ? 'month'
          : period === 2
          ? 'year'
          : 'day'
      const categoryText = category === 0 ? 'giftcard' : 'coin'
      const typeText = category === 0 || category === 1 ? 'sale' : 'purchase'
      const res = await dataApi.data().getLeaderboard({
        period: periodText,
        category: categoryText,
        type: typeText,
        requester: 'user'
      })
      if (categoryText === 'giftcard') {
        this.users = res.data.data.giftcardLeaderboard
      }
      if (categoryText === 'coin' && typeText === 'sale') {
        this.users = res.data.data.cryptoSalesLeaderboard
      }
      if (categoryText === 'coin' && typeText === 'purchase') {
        this.users = res.data.data.cryptoPurchaseLeaderboard
      }
      this.loadingData = false
    },
    getColorBg() {
      const colors = [
        'primary',
        'accent',
        'pink',
        'brown',
        'green',
        'purple',
        'yellow',
        'red',
        'indigo',
        'blue',
        'light-blue',
        'teal',
        'cyan',
        'green',
        'light-green',
        'lime',
        'amber',
        'orange',
        'blue-grey'
      ]
      return colors[Math.floor(Math.random() * colors.length)]
    },
    async getData() {
      const res = await dataApi.data().siteSettings('user')
      this.data = res.data.data
    }
  }
}
</script>
